@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;600&family=Dancing+Script&family=Marck+Script&family=Roboto+Slab:wght@100;300;400&display=swap');

:root {
    /* Colors */
    --color-stone-light: rgb(245 245 244);
    --color-stone-dark: #292524;
    --color-gold-dark: #b47c45;
    --color-gold-light: #f1c67b;
    --color-error: #ef4444;
    --color-emerald-light: #047857;
    --color-gold-gradient: linear-gradient(157deg, var(--color-gold-dark) 18%, var(--color-gold-light) 100%);
}

body {
    margin: 0;
    padding: 0;
    background-color: #292B33;
}